'use client';

import { Check } from 'lucide-react';
import { Toaster as Sonner, toast } from 'sonner';
type ToasterProps = React.ComponentProps<typeof Sonner>;
const Toaster = ({
  ...props
}: ToasterProps) => {
  return <Sonner theme="light" className="toaster group mt-8" position="top-center" richColors visibleToasts={5} toastOptions={{
    classNames: {
      toast: 'group toast group-[.toaster]:bg-white group-[.toaster]:text-gray-950 group-[.toaster]:border-gray-200 group-[.toaster]:shadow-lg dark:group-[.toaster]:bg-gray-950 dark:group-[.toaster]:text-gray-50 dark:group-[.toaster]:border-gray-800 text-base rounded-xl px-6',
      description: 'group-[.toast]:text-gray-500 dark:group-[.toast]:text-gray-400',
      actionButton: 'group-[.toast]:bg-gray-900 group-[.toast]:text-gray-50 dark:group-[.toast]:bg-gray-50 dark:group-[.toast]:text-gray-900',
      cancelButton: 'group-[.toast]:bg-gray-100 group-[.toast]:text-gray-500 dark:group-[.toast]:bg-gray-800 dark:group-[.toast]:text-gray-400'
    }
  }} icons={{
    success: <Check className="text-white" size={20} />
  }} {...props} data-sentry-element="Sonner" data-sentry-component="Toaster" data-sentry-source-file="sonner.tsx" />;
};
export { toast, Toaster };