import { DialogProps } from '@radix-ui/react-dialog';
import { AlertTriangle, Ban, Check, Info } from 'lucide-react';
import { FC, useCallback, useState } from 'react';
import { AlertDialog, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from '../../components/ui/alert-dialog';
import { Button } from '../../components/ui/button';
import { cn } from '../../utils/cn';
import { AlertButtonPayload, AlertPayload } from './types';
type ShellAlertDialogProps = AlertPayload & Pick<DialogProps, 'open' | 'onOpenChange'> & {
  onClose: (value: any) => void;
};
const levelConfig = {
  info: {
    icon: Info,
    className: 'text-blue'
  },
  success: {
    icon: Check,
    className: 'text-success'
  },
  destructive: {
    icon: AlertTriangle,
    className: 'text-destructive'
  }
};
export const ShellAlertDialog: FC<ShellAlertDialogProps> = ({
  title,
  level,
  message,
  actionButtons,
  open,
  cancelButtonLabel,
  onClose,
  contentClassName
}) => {
  const {
    className: iconClassName,
    icon: Icon
  } = levelConfig[level ?? 'info'];
  return <AlertDialog open={open} onOpenChange={() => onClose(null)} data-sentry-element="AlertDialog" data-sentry-component="ShellAlertDialog" data-sentry-source-file="shell-alert-dialog.tsx">
      <AlertDialogContent className={cn('flex flex-col items-center justify-center text-center', contentClassName)} data-sentry-element="AlertDialogContent" data-sentry-source-file="shell-alert-dialog.tsx">
        <AlertDialogHeader data-sentry-element="AlertDialogHeader" data-sentry-source-file="shell-alert-dialog.tsx">
          {Icon && <Icon size={80} className={iconClassName} />}
          {title && <AlertDialogTitle>{title}</AlertDialogTitle>}
        </AlertDialogHeader>
        <AlertDialogDescription data-sentry-element="AlertDialogDescription" data-sentry-source-file="shell-alert-dialog.tsx">{message}</AlertDialogDescription>
        <AlertDialogFooter className={actionButtons ? 'sm:justify-end' : ''} data-sentry-element="AlertDialogFooter" data-sentry-source-file="shell-alert-dialog.tsx">
          {actionButtons ? <>
              <Button onClick={() => onClose(null)} type="button" variant="outline">
                {cancelButtonLabel ?? 'Cancel'}
              </Button>
              {actionButtons?.map(button => <ShellAlertDialogActionButton key={button.label} button={button} onClose={onClose} />)}
            </> : <Button variant="primary" onClick={() => onClose(true)}>
              OK
            </Button>}
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>;
};
function ShellAlertDialogActionButton<T>({
  button,
  onClose
}: {
  button: AlertButtonPayload<T>;
  onClose: (value?: T) => void;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const handleClick = useCallback(async () => {
    if (typeof button.value === 'function') {
      setIsLoading(true);
      const resolvedValue: T = await (button.value as Function)();
      setIsLoading(false);
      onClose(resolvedValue);
    } else {
      onClose(button.value);
    }
  }, [button, onClose]);
  return <Button key={button.label} {...button.props} loading={isLoading} className={button.props?.className} onClick={handleClick} type="button" data-sentry-element="Button" data-sentry-component="ShellAlertDialogActionButton" data-sentry-source-file="shell-alert-dialog.tsx">
      {button.label}
    </Button>;
}